const X = (() => {
  "use strict"

  const BASE_URL = "https://x-widget-app.vercel.app/api/identify"

  const TOAST_TEMPLATE = (data, position, id) =>
    `<div class="x-toast" id="${id}" style="${position}" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="x-toast-header">
        <span class="x-toast-icon" role="img" aria-label="Icon">${data.icon}</span>
        <strong>${data.title}</strong>
        <button type="button" id="x-dismiss-button" aria-label="Close">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none"><g stroke="#212121" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" clip-path="url(#a)"><path d="m1.5 1.5 9 9M10.5 1.5l-9 9"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h12v12H0z"/></clipPath></defs></svg>
        </button>
      </div>
      <div class="x-toast-body">
        <p class="x-toast-message">${data.message}</p>
        <a href="${data.cta_url}" class="x-toast-cta">${data.cta}</a>
      </div>
    </div>`

  const WIDGET_CSS = () => `
    body {background-color: #fff;font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;}.x-toast {position: fixed;z-index: 9999;background-color: #fff;border: 1px solid rgb(0 0 0 / 18%);border-radius: 0.375rem;box-shadow: 0 0.5rem 1rem rgba(33,37,41, 0.15);font-size: 14px;animation: bouncy-animation 0.4s ease-in-out forwards;transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);}.x-toast-header {padding: 8px 16px;border-bottom: 1px solid rgb(0 0 0 / 18%);display: flex;align-items: center;color: #444950;}.x-toast-body {padding: 14px 16px;}.x-toast-message {margin-top: 0;padding-top: 0;color: #212529;}.x-toast-icon {display: flex;align-items: center;width: 24px;height: 24px;margin-right: 10px;}.x-toast-cta {display: inline-flex;background-color: #0f62fe;color: #fff;font-weight: 600;padding: 8px;text-decoration: none;border-radius: 3px;font-size: 13px;}#x-dismiss-button {background: transparent;border: none;cursor: pointer;display: flex;align-items: center;opacity: 0.6;position: absolute;right: 10px;}#x-dismiss-button:hover {opacity: 1;transition: .1s opacity ease-in-out;}@media (max-width: 468px) {.x-toast {top: 16px !important;left: 16px !important;right: 16px !important;}}@media (min-width: 468px) {.x-toast {max-width: 400px;min-width: 350px;}}@keyframes bouncy-animation {0% {transform: translateY(-60%);}50% {transform: translateY(20%);}100% {transform: translateY(0%);}}
  `
  class Widget {
    constructor({
      selector,
      app_id,
      user_id,
      user_hash,
      onWidgetLoad = () => { },
    }) {
      this.config = { selector, app_id, user_id, user_hash, onWidgetLoad }
      this.randId = "x" + Math.random().toString(16).slice(2)
      this.appendStylesheet();
      this.fetchData()
    }
    
    async fetchData() {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: this.config.user_id,
            user_hash: this.config.user_hash,
            app_id: this.config.app_id,
            page_url: window.location.href,
          })
        });
        const data = await response.json();
        this.renderWidget(data);
        this.config.onWidgetLoad();
      } catch (error) {
        // console.error(error);
      }
    } 

    renderWidget(widget) {
      const id = this.randId;
      
      if (widget.type === "toast") {
        const toast = TOAST_TEMPLATE(widget, this.setPosition(widget.position), id);
        document.body.insertAdjacentHTML('beforeend', toast);
      }

      const closeButton = document.getElementById("x-dismiss-button");
      closeButton.addEventListener("click", () => {
        this.dismissWidget();
      });
    }

    dismissWidget() {
      const widgetElement = document.getElementById(this.randId);
      widgetElement.style.transition = "opacity .1s";
      widgetElement.style.opacity = 0;
      setTimeout(function() {
        widgetElement.parentNode.removeChild(widgetElement);
      }, 1000);      
    }

    appendStylesheet() {
      const styleTag = document.createElement("style");
      styleTag.id = "x-styles";
      styleTag.innerHTML = WIDGET_CSS();
      document.body.insertAdjacentElement("beforeend", styleTag);
    }

    setPosition(position) {
      let style = "";
      switch (position) {
        case "top-right":
          style = "right: 24px; top: 24px;";
          break;
        case "top-center":
          style = "top: 24px; right: 0; left: 0; margin: 0 auto;";
          break;
        case "top-left":
          style = "left: 24px; top: 24px;";
          break;
        case "bottom-right":
          style = "right: 24px; bottom: 24px;";
          break;
        case "bottom-left":
          style = "left: 24px; bottom: 24px;";
          break;
      }
      return style;
    }

    logMessage(state, message,) {
      if (state === "error") {
        console.error(message);
      } else if (state === "warning") {
        console.warn(message);
      }
    }
  }

  const show = (options = {}) => {
    new Widget(options)
  };

  return {
    show
  };

})();

export default X

if (typeof window !== "undefined") {
  window.X = X
}